// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-ampfire-hpv-screening-js": () => import("./../../../src/pages/products/ampfire-hpv-screening.js" /* webpackChunkName: "component---src-pages-products-ampfire-hpv-screening-js" */),
  "component---src-pages-products-diago-t-790-m-js": () => import("./../../../src/pages/products/diago-t790m.js" /* webpackChunkName: "component---src-pages-products-diago-t-790-m-js" */),
  "component---src-pages-products-hpv-xpress-matrix-js": () => import("./../../../src/pages/products/hpv-xpress-matrix.js" /* webpackChunkName: "component---src-pages-products-hpv-xpress-matrix-js" */),
  "component---src-pages-products-indigen-real-time-js": () => import("./../../../src/pages/products/indigen-real-time.js" /* webpackChunkName: "component---src-pages-products-indigen-real-time-js" */),
  "component---src-pages-products-sars-cov-2-real-time-js": () => import("./../../../src/pages/products/sars-cov-2-real-time.js" /* webpackChunkName: "component---src-pages-products-sars-cov-2-real-time-js" */),
  "component---src-pages-products-sars-cov-2-saliva-nucleic-js": () => import("./../../../src/pages/products/sars-cov-2-saliva-nucleic.js" /* webpackChunkName: "component---src-pages-products-sars-cov-2-saliva-nucleic-js" */),
  "component---src-pages-services-co-testing-js": () => import("./../../../src/pages/services/co-testing.js" /* webpackChunkName: "component---src-pages-services-co-testing-js" */),
  "component---src-pages-services-hpv-genotyping-js": () => import("./../../../src/pages/services/hpv-genotyping.js" /* webpackChunkName: "component---src-pages-services-hpv-genotyping-js" */),
  "component---src-pages-services-hpv-highrisk-js": () => import("./../../../src/pages/services/hpv-highrisk.js" /* webpackChunkName: "component---src-pages-services-hpv-highrisk-js" */),
  "component---src-pages-services-liquid-based-cytology-js": () => import("./../../../src/pages/services/liquid-based-cytology.js" /* webpackChunkName: "component---src-pages-services-liquid-based-cytology-js" */)
}

